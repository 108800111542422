.animated.delay-01 {
	animation-delay: 0s;
	-webkit-animation-delay: 0s;
	-moz-animation-delay: 0s;
	-o-animation-delay: 0s;
}

.animated.delay-02 {
	animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	-moz-animation-delay: 0.5s;
	-o-animation-delay: 0.5s;
}

.animated.delay-03 {
	animation-delay: 1s;
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	-o-animation-delay: 1s;
}

.animated.delay-04 {
	animation-delay: 1.5s;
	-webkit-animation-delay: 1.5s;
	-moz-animation-delay: 1.5s;
	-o-animation-delay: 1.5s;
}

.animated.delay-05 {
	animation-delay: 2s;
	-webkit-animation-delay: 2s;
	-moz-animation-delay: 2s;
	-o-animation-delay: 2s;
}

.animated.delay-06 {
	animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
	-moz-animation-delay: 2.5s;
	-o-animation-delay: 2.5s;
}

.animated.delay-07 {
	animation-delay: 3s;
	-webkit-animation-delay: 3s;
	-moz-animation-delay: 3s;
	-o-animation-delay: 3s;
}

.animated.delay-08 {
	animation-delay: 3.5s;
	-webkit-animation-delay: 3.5s;
	-moz-animation-delay: 3.5s;
	-o-animation-delay: 3.5s;
}

.animated.delay-09 {
	animation-delay: 4s;
	-webkit-animation-delay: 4s;
	-moz-animation-delay: 4s;
	-o-animation-delay: 4s;
}

.animated.delay-10 {
	animation-delay: 4.5s;
	-webkit-animation-delay: 4.5s;
	-moz-animation-delay: 4.5s;
	-o-animation-delay: 4.5s;
}

.animated.delay-11 {
	animation-delay: 5s;
	-webkit-animation-delay: 5s;
	-moz-animation-delay: 5s;
	-o-animation-delay: 5s;
}

.animated.delay-12 {
	animation-delay: 5.5s;
	-webkit-animation-delay: 5.5s;
	-moz-animation-delay: 5.5s;
	-o-animation-delay: 5.5s;
}

.animated.delay-13 {
	animation-delay: 6s;
	-webkit-animation-delay: 6s;
	-moz-animation-delay: 6s;
	-o-animation-delay: 6s;
}

.animated.delay-14 {
	animation-delay: 6.5s;
	-webkit-animation-delay: 6.5s;
	-moz-animation-delay: 6.5s;
	-o-animation-delay: 6.5s;
}

.animated.delay-15 {
	animation-delay: 7s;
	-webkit-animation-delay: 7s;
	-moz-animation-delay: 7s;
	-o-animation-delay: 7s;
}

.animated.delay-16 {
	animation-delay: 7.5s;
	-webkit-animation-delay: 7.5s;
	-moz-animation-delay: 7.5s;
	-o-animation-delay: 7.5s;
}

.animated.delay-17 {
	animation-delay: 8s;
	-webkit-animation-delay: 8s;
	-moz-animation-delay: 8s;
	-o-animation-delay: 8s;
}

.animated.delay-18 {
	animation-delay: 8.5s;
	-webkit-animation-delay: 8.5s;
	-moz-animation-delay: 8.5s;
	-o-animation-delay: 8.5s;
}

.animated.delay-19 {
	animation-delay: 9s;
	-webkit-animation-delay: 9s;
	-moz-animation-delay: 9s;
	-o-animation-delay: 9s;
}

.animated.delay-20 {
	animation-delay: 9.5s;
	-webkit-animation-delay: 9.5s;
	-moz-animation-delay: 9.5s;
	-o-animation-delay: 9.5s;
}

.animated {
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	-o-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	   -moz-animation-fill-mode: both;
	     -o-animation-fill-mode: both;
	        animation-fill-mode: both;
}


@-webkit-keyframes wobble {
  0% { -webkit-transform: translateX(0%); }
  15% { -webkit-transform: translateX(-25%) rotate(-5deg); }
  30% { -webkit-transform: translateX(20%) rotate(3deg); }
  45% { -webkit-transform: translateX(-15%) rotate(-3deg); }
  60% { -webkit-transform: translateX(10%) rotate(2deg); }
  75% { -webkit-transform: translateX(-5%) rotate(-1deg); }
  100% { -webkit-transform: translateX(0%); }
}

@-moz-keyframes wobble {
  0% { -moz-transform: translateX(0%); }
  15% { -moz-transform: translateX(-25%) rotate(-5deg); }
  30% { -moz-transform: translateX(20%) rotate(3deg); }
  45% { -moz-transform: translateX(-15%) rotate(-3deg); }
  60% { -moz-transform: translateX(10%) rotate(2deg); }
  75% { -moz-transform: translateX(-5%) rotate(-1deg); }
  100% { -moz-transform: translateX(0%); }
}

@-o-keyframes wobble {
  0% { -o-transform: translateX(0%); }
  15% { -o-transform: translateX(-25%) rotate(-5deg); }
  30% { -o-transform: translateX(20%) rotate(3deg); }
  45% { -o-transform: translateX(-15%) rotate(-3deg); }
  60% { -o-transform: translateX(10%) rotate(2deg); }
  75% { -o-transform: translateX(-5%) rotate(-1deg); }
  100% { -o-transform: translateX(0%); }
}

@keyframes wobble {
  0% { transform: translateX(0%); }
  15% { transform: translateX(-25%) rotate(-5deg); }
  30% { transform: translateX(20%) rotate(3deg); }
  45% { transform: translateX(-15%) rotate(-3deg); }
  60% { transform: translateX(10%) rotate(2deg); }
  75% { transform: translateX(-5%) rotate(-1deg); }
  100% { transform: translateX(0%); }
}

.wobble {
	-webkit-animation-name: wobble;
	-moz-animation-name: wobble;
	-o-animation-name: wobble;
	animation-name: wobble;
}

